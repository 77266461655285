
import './App.css';
import Navbar2 from './components/Navbar2';
import Slider5 from './components/Slider5';
import Dummy from './components/Dummy';
import Home from './components/Home';
import { Route, Routes } from 'react-router-dom';
import GlosaryInformation from './components/GlosaryInformation';

function App() {
  return (
    <div>
   

      {/* <Navbar2></Navbar2>
      <Dummy></Dummy>
      <Slider5></Slider5> */}

      <Home></Home>

      {/* <GlosaryInformation></GlosaryInformation> */}
      {/* <Routes>
        <Route element={<Home></Home>} path='/'></Route>
        <Route element={<GlosaryInformation></GlosaryInformation>} path='/glosarySubcategoryInformation'></Route>
      </Routes> */}
 
    </div>
  );
}

export default App;
