import React from 'react'
import './GlodsarySubData.css'

const GlodsarySubData = ({data}) => {
  function glosarySubcategoryClicked(a){
     
    console.log(a.id)

    localStorage.setItem("glosaryData",a.id);

  }
  return (
    <div>
    {
        console.log("glosarry sub data", data)
    }

<div className='glosary-sub-category-container'  >
 
     {
        data.map((data, index)=>(
         
            <div key={index} className='glosary-subcategory-data'   onClick={()=>glosarySubcategoryClicked(data)}>
              <a href='/glosarySubcategoryInformation' className='ram'> 
               <img src={`https://geniussteam.in/servixoindia/public/uploads/service-icon/${data.service_icon}`}   ></img>
                <p>{data.service_name}</p>
                </a>
            </div>

           
        ))
     }
      
     
</div>
    </div>
  )
}

export default GlodsarySubData