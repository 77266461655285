import React from 'react'
import './Events.css'

const Events = ({data}) => {



  
  return (

   
    <div>
    
    {
        console.log("ok ok ", data.data.Events)
    }
    


<div className='events-data-container'>

 
{

    data.data.Events.map((data,index )=>(
        <div key={index}   className='event-data-box'>
          

        
        <img  src={`https://geniussteam.in/servixoindia/public/uploads/event-image/${data.event_image}`}></img>

          <h3>{data.name}</h3>
          <p>{data.description}</p>
          <button >Shop Now</button>
        </div>
    ))
}
</div>



    </div>
  )
}

export default Events