import React from 'react'
import GlodsarySubData from './GlodsarySubData'
import './GlosaryData.css'

const GlosaryData = ({data}) => {
  return (

    
    <div className='glosary-data-container'>
    
    


    {
        data.data.AllCategories.map((data,index)=>(
            <div key={index} >
                
                <h1>{data.category_name}</h1>
                <div className='glosary-data-original'>

                <GlodsarySubData data={data.subcategories}></GlodsarySubData>
                </div>
                 
            </div>
        ))
    }
    </div>
  )
}

export default GlosaryData