import React, { useEffect, useState } from 'react'
 
 

  
import Slider5 from './Slider5'
 
import Navbar2 from './Navbar2'
import Events from './Events';
import GlosaryData from './GlosaryData';
import Footer from './Footer';
 
 

const Home = () => {

  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [data, setData] = useState(null); 
  const [deviceAddress, setDeviceAddress] = useState('');

 // console.log(data);

  useEffect(() => {
    fetchImages();

   // fetchDeviceAddress();
    //fetchCount('ram')
}, []);



  const fetchImages = async () => {
    try {
       // const urls = [];
        const url = 'https://geniussteam.in/servixoindia/public/api/get-home-page-data';
        const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNTJlZWMwOWRlMmZiODc5ZmRjOWY5Mjg1MjU0ZGJkMjEzN2UyZTIyNWFjZTJlZDgzYzdjODUxYjdkMTY5ZWYxOGRhNjcyZTFhYjhmMDExMjIiLCJpYXQiOjE3MTU1MjEwODMuMDE1NjEzMDc5MDcxMDQ0OTIxODc1LCJuYmYiOjE3MTU1MjEwODMuMDE1NjE2ODkzNzY4MzEwNTQ2ODc1LCJleHAiOjE3NDcwNTcwODIuOTk4NDIxOTA3NDI0OTI2NzU3ODEyNSwic3ViIjoiMSIsInNjb3BlcyI6W119.pMl8Btb0JknrPc9kBS389zor1aHgiGkQLeSGW9kc7qTbakk-MWitbjueHefaqnDe_AuZQUA2zsF8m7pDwyBDJV6B1BxjWv3_kPV3P60M0wiqt1d3rFIgKtpDGVjr7P8GyRCrgkyD7mLYWRgaD4RVKELn0ter46YqFTo2Yna9h9XVbeKDKhJlyjrX1Ke3s-vSb8fflBSLE6j2pR1z5eARB7HYoQhEktlFyD4d6wPLYms0E3VC_XkGNMcqFzs6mnv449eBkO8FK8S8O1LoKrCEb_DE3TkzLJqsysk_o2eKmnmQ9oRL4l28B2jETkYsL_BWthiS7kRNrA6tsfLgsZvNancjyfxwBB6bKjdeXh-pEAugfo6SZL1LfnjvHyNB1uZRUwIMdA1aOmbCTeEtWswh7TsIPnjjPCwrpKxUcVC-qn_XYA3wOIeMKUW6pPCnzwScQEh5jJLAgO3vxJUCCNkLOyosTrn4i9Prs-OUJeuGMzX_EuQplk7U_fsYZ40-VTHnTXKzhdJRx7EtW3oRfQxvGKpMDTlcn4pInusQajKIXaWh48mjriEIWHCV2ROPyLe_19O6E4emK_av-R1gtUHDuw9_1ubMwC_cq_Lw1_qMTOABK51ZMiUskH3rRtNuZ7thV_TBTanXnkmT__0j3z-uaP-gNPiG1TNY8bk3kXeh5rA';
        const requestData = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        const response = await fetch(url, requestData);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log("home data ",data.data);
        setImagesLoaded(true)
        setData(data);
        return data;

        // data.data.SliderImages.forEach((item) => {
        //     urls.push(`https://servixoindia.com/servixoindia/uploads/slider-images/${item.images}`);
        // });
        //setSlideImages(urls);
       // setImagesLoaded(true); // Mark images as loaded
    } catch (error) {
        console.error('Error fetching image URLs:', error);
    }
};




// const fetchDeviceAddress = async () => {
//   try {
//       const response = await fetch('https://api.ipify.org/?format=json');
//       const data = await response.json();
//       console.log(data.ip)
//       setDeviceAddress(data.ip);
//       await fetchCount(data.ip)
//   } catch (error) {
//       console.error('Error fetching device address:', error);
//   }
// };





//  const fetchCount = async (uniqueId)=>{


  
//   try {
//     // Create a new FormData object
//     const formData = new FormData();
    
//     // Append the vid parameter to the FormData object
//     formData.append('vid', `${uniqueId}`);

//     // Make the POST request
//     const apiUrl = 'https://servixoindia.com/servixoindia/api/add_web_visit_count';
//     const response = await fetch(apiUrl, {
//       method: 'POST',
//       body: formData
//     });

//     // Parse the response as JSON
//     const responseData = await response.json();
    
//     console.log("we have in  1 3",responseData.data)
//     // Set the response state
//     //setResponse(responseData);
//   } catch (error) {
//     console.error('Error: has been occourred ', error);

//   }



//  }



  return (


    

    <div>

       
       {/* <div className='fixedText'>
       <a href='https://api.whatsapp.com/send/?phone=%2B917387649037&text=Hi&type=phone_number&app_absent=0'> 
       <FaWhatsappSquare  className='icon'/>
       </a>
       </div> */}
 

 

      <Navbar2></Navbar2>
    
    <Slider5></Slider5>




<div>

    {
        imagesLoaded ? (<Events data={data}></Events>) : (<div></div>)
    }

    {
      imagesLoaded ? (<div><GlosaryData data={data}></GlosaryData></div>):(<div></div>)
    }

    {
      imagesLoaded ?(<div><Footer></Footer></div>):(<div></div>)
    }
</div>







    <div>

  
    
  {/* {

    imagesLoaded ?
    (<Categories  data={data} ></Categories>): (<div></div>)


  } */}

  </div>

  <div></div>

{/* {
    imagesLoaded ? 
 ( <ServicesVideoSnap data={data}></ServicesVideoSnap> ) : (<div></div>) 
} */}



{/* {
     imagesLoaded ? (<EventData   data={data} ></EventData>): (<div></div>)     
} */}

{/* {
  imagesLoaded ?
 ( <ServiceSubCategory data={data}></ServiceSubCategory> ) : (<div></div>)
} */}


{/* {
  imagesLoaded ?
  (<MostBookedServiceSubCategory data={data}></MostBookedServiceSubCategory>):(<div></div> )
} */}


{/* <ElfSightApps></ElfSightApps> */}
 

    {/* <Footer></Footer> */}



   
  
    


    </div>
  )
}

export default Home