import React, { useState, useEffect } from 'react';
import './slider3.css'; // Import your CSS file

  

  

function Slider5() {
    const [counter, setCounter] = useState(0);
    const [slideImages, setSlideImages] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    

    useEffect(() => {
        fetchImages();
    }, []);

    useEffect(() => {
        if (imagesLoaded) {
            const intervalId = setInterval(() => {
                slideNext();
            }, 3000);

            return () => clearInterval(intervalId); // Clear the interval when component unmounts
        }
    }, [imagesLoaded]);

    const fetchImages = async () => {
        try {
            const urls = [];
            const url = 'https://geniussteam.in/servixoindia/public/api/get-home-page-data';
            const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNTJlZWMwOWRlMmZiODc5ZmRjOWY5Mjg1MjU0ZGJkMjEzN2UyZTIyNWFjZTJlZDgzYzdjODUxYjdkMTY5ZWYxOGRhNjcyZTFhYjhmMDExMjIiLCJpYXQiOjE3MTU1MjEwODMuMDE1NjEzMDc5MDcxMDQ0OTIxODc1LCJuYmYiOjE3MTU1MjEwODMuMDE1NjE2ODkzNzY4MzEwNTQ2ODc1LCJleHAiOjE3NDcwNTcwODIuOTk4NDIxOTA3NDI0OTI2NzU3ODEyNSwic3ViIjoiMSIsInNjb3BlcyI6W119.pMl8Btb0JknrPc9kBS389zor1aHgiGkQLeSGW9kc7qTbakk-MWitbjueHefaqnDe_AuZQUA2zsF8m7pDwyBDJV6B1BxjWv3_kPV3P60M0wiqt1d3rFIgKtpDGVjr7P8GyRCrgkyD7mLYWRgaD4RVKELn0ter46YqFTo2Yna9h9XVbeKDKhJlyjrX1Ke3s-vSb8fflBSLE6j2pR1z5eARB7HYoQhEktlFyD4d6wPLYms0E3VC_XkGNMcqFzs6mnv449eBkO8FK8S8O1LoKrCEb_DE3TkzLJqsysk_o2eKmnmQ9oRL4l28B2jETkYsL_BWthiS7kRNrA6tsfLgsZvNancjyfxwBB6bKjdeXh-pEAugfo6SZL1LfnjvHyNB1uZRUwIMdA1aOmbCTeEtWswh7TsIPnjjPCwrpKxUcVC-qn_XYA3wOIeMKUW6pPCnzwScQEh5jJLAgO3vxJUCCNkLOyosTrn4i9Prs-OUJeuGMzX_EuQplk7U_fsYZ40-VTHnTXKzhdJRx7EtW3oRfQxvGKpMDTlcn4pInusQajKIXaWh48mjriEIWHCV2ROPyLe_19O6E4emK_av-R1gtUHDuw9_1ubMwC_cq_Lw1_qMTOABK51ZMiUskH3rRtNuZ7thV_TBTanXnkmT__0j3z-uaP-gNPiG1TNY8bk3kXeh5rA';
            const requestData = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            };

            const response = await fetch(url, requestData);


            console.log(response);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            console.log("mai slider hu ",data.data.SliderImages);


            data.data.SliderImages.forEach((item) => {
                urls.push(`https://geniussteam.in/servixoindia/public/uploads/slider-images/${item.images}`);
            });
            setSlideImages(urls);
             setImagesLoaded(true);
            
            // Mark images as loaded
        } catch (error) {
            console.error('Error fetching image URLs:', error);
        }
    };

    const slideNext = () => {
        setCounter((prevCounter) => (prevCounter + 1) % slideImages.length);
    };

    const slidePrev = () => {
        setCounter((prevCounter) => (prevCounter === 0 ? slideImages.length - 1 : prevCounter - 1));
    };

    const switchImage = (index) => {
        setCounter(index);
    };

    return (

        
        <div>

      
 


        <div className="    slider-section ">



            <div className="slide-container">

                <div className="slides">
                    {slideImages.map((image, index) => (
                        <img   key={index} src={image} alt={`Slide ${index + 1}`} className={index === counter ? 'active' : 'inactive'}  />
                    ))}
                </div>
                <div className="buttons">
                    <span className="next" onClick={slideNext}>&#10095;</span>
                    <span className="prev" onClick={slidePrev}>&#10094;</span>
                </div>
                <div className="dotsContainer">
                    {slideImages.map((_, index) => (
                        <div key={index} className={`dot ${index === counter ? 'active' : ''}`} onClick={() => switchImage(index)}></div>
                    ))}
                </div>
                   

                     
      
            </div>  


        </div>

        </div>
    );
}

export default Slider5;
